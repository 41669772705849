import { ConsoleMessageType, CsConsole } from '../utils/console';
import { assigned } from '../utils/helper';


/*------------------------------------------*/
const htmlEntity = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '\\\'': '"',
    '&#039;': '\'',
    '&bsol;': '\\\\'
};

/*------------------------------------------*/
export function registerOnShowModal(comp: JQuery<HTMLElement>): void {
    comp.on('shown.bs.modal', function () {
        //Die Laenge ist immer mindestens 1, da das Modal schon hinzugefuegt wurde
        let zIndex = 10 + Math.max(
            ...Array.from(document.querySelectorAll('.modal.show:not(.csspinner)'), el =>
                parseFloat((el as HTMLElement).style.zIndex),
            ).filter(zIndex => !Number.isNaN(zIndex)),
            1030,
        );
        this.style.zIndex = zIndex.toString();

        window.setTimeout(function () {
            let backdrops = document.querySelectorAll('.modal-backdrop:not(.modal-stack):not(.csspinner)');

            for (let i = 0; i < backdrops.length; i++) {
                let backdrop = backdrops[i] as HTMLElement;
                backdrop.style.zIndex = (zIndex + i * 10 - 1).toString();
                backdrop.classList.add('modal-stack');
            }
        }, 0);

        initModalEvents(comp);

        comp.focus();
    });

    // Wenn wir gestacked sind, müssen wir beim schliessen des obersten Dialogs den da drunter fokusieren
    comp.on('hide.bs.modal', function () {
        var modal = null;
        $('.modal:visible').each(function () {
            if ($(this).css('z-index') == comp.css('z-index'))
                return;

            if (modal == null || $(this).css('z-index') > modal.css('z-index')) {
                modal = $(this);
            }
        });

        if (modal != null) {
            modal.focus();
        }
    });

    comp.on('show.bs.modal', function () {
        // nur beim ersten Modal!
        if (!(document.querySelector('.modal')?.classList?.contains('show'))) {
            // sind wir gescrollt?
            if (document.body.scrollHeight > window.innerHeight) {
                // Scroll via css "deaktivieren"
                document.documentElement.classList.add('modal-noscroll');
            }
        }
    });

    comp.on('hidden.bs.modal', function (e) {
        // nur wenn es nicht noch ein sichtbares Modal gibt!
        if (!(document.querySelector('.modal')?.classList?.contains('show'))) {
            // Scroll "aktivieren"
            document.documentElement.classList.remove('modal-noscroll');
        }
    });
}

// für Delphi freigeben
window[registerOnShowModal.name] = registerOnShowModal;

function initModalEvents(comp: JQuery<HTMLElement>): void {
    comp.on('keydown', {}, modalExecOnKeyDownEvent);
}

function modalExecOnKeyDownEvent(event: Event): void {
    let keyEvent = event as KeyboardEvent;

    if (!assigned(keyEvent)) {
        console.debug(event);
        throw 'Event is not a KeyboardEvent';
    }

    let dialogId = (<Element>keyEvent.target).id;

    let dialog = $('#' + dialogId);
    // wir wollen mit escape schließen
    if (dialog.data('keyboard') && keyEvent.key == 'Escape') {
        dialog.modal('hide');
        //unregisterComponent(dialogId);
        //$('#' + dialogId).remove();
    }
}

function onStackModal(comp: JQuery<HTMLElement>): void {
    var zIndex = 1040 + (10 * $('.modal:visible').length);
    comp.css('z-index', zIndex);
    window.setTimeout(function () {
        $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
    }, 0);
}

/*------------------------------------------*/
function HTMLEntity2String(str: string): string {
    // macht aus &amp; ein & usw.
    Object.keys(htmlEntity).forEach(key => {
        let re = new RegExp(key, 'g');
        str = str.replace(re, htmlEntity[key]);
    });

    return str;
}
/*------------------------------------------*/
export function String2HTMLEntity(str: string): string {
    // macht aus & ein &amp; usw.
    Object.keys(htmlEntity).forEach(key => {
        var re = new RegExp(htmlEntity[key], 'g');
        str = str.replace(re, key);
    });
    return str;
}

/*------------------------------------------*/
/* Error */
/*------------------------------------------*/

/*------------------------------------------*/
export function AjaxError(xhr: JQueryXHR): void {
    let message = `Ajax -> Connection to server failed: ${xhr.status}`;
    CsConsole(message, ConsoleMessageType.Error);
}

