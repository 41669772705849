const PRODCTION_ENV = 'production';

/**
 * Check if code is running in productive environment.
 * @remarks Only returns true, if the production flag is explictly set during build. Undefined flag returns false!
 */
export function isProductive(): boolean {
    return process.env.NODE_ENV === PRODCTION_ENV;
}

/**
 * Check if code is running in development environment.
 * @remarks Always returns true, if the production flag is not explicitly set during build. Undefined flag returns true!
 */
export function isDev(): boolean {
    return !isProductive();
}