export type SizeDefinition = { width: number, height: number };

export type Vector2D = { x1: number, x2: number };

export type Range = { a: number, b: number }

export enum Direction { Up, Right, Down, Left }

export class Point {
    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    asVector(): Vector2D {
        return { x1: this.x, x2: this.y };
    }

    equals(otherPoint: Point): boolean {
        return this.x === otherPoint.x && this.y === otherPoint.y;
    }
}

export function addVector2D(u: Vector2D, v: Vector2D): Vector2D {
    return {
        x1: u.x1 + v.x1,
        x2: u.x2 + v.x2,
    }
}

export function subVector2D(u: Vector2D, v: Vector2D): Vector2D {
    return {
        x1: u.x1 - v.x1,
        x2: u.x2 - v.x2,
    }
}

export function multVector2D(v, m): Vector2D {
    return {
        x1: v.x1 * m,
        x2: v.x2 * m,
    }
}

export function lengthVector2D(v: Vector2D): number {
    return Math.sqrt(v.x1 * v.x1 + v.x2 * v.x2);
}

export function normalizeVector2D(v: Vector2D): Vector2D {
    let l = lengthVector2D(v);
    if (l > 0) {
        v.x1 = v.x1 / l;
        v.x2 = v.x2 / l;
    }
    return v;
}

export function isParallelVector2D(u: Vector2D, v: Vector2D): boolean {
    u = normalizeVector2D(u);
    v = normalizeVector2D(v);

    return Math.abs(u.x1) === Math.abs(v.x1) && Math.abs(u.x2) === Math.abs(v.x2);
}

export function vector2DBetweenPoints(from: Point, to: Point): Vector2D {
    return {
        x1: to.x - from.x,
        x2: to.y - from.y,
    };
}

export function distancePointPoint(a: Point, b: Point): number {
    return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
}

export function directionPointToPoint(srcPoint: Point, dstPoint: Point): Direction {
    if (srcPoint.y === dstPoint.y) {
        if (srcPoint.x < dstPoint.x)
            return Direction.Right;
        else
            return Direction.Left;
    }
    else {
        let f = (dstPoint.x - srcPoint.x) / (dstPoint.y - srcPoint.y);

        if (srcPoint.y < dstPoint.y) {
            // liegt drunter
            if (Math.abs(f) <= 1)
                return Direction.Down;
            else if (f > 1)
                return Direction.Right;
            else if (f < -1)
                return Direction.Left;
        }
        else {
            // liegt drüber
            if (Math.abs(f) <= 1)
                return Direction.Up;
            else if (f > 1)
                return Direction.Left;
            else if (f < -1)
                return Direction.Right;
        }
    }
}

export function overlappingPoints(a: Point, b: Point, epsilon: number = 1): boolean {
    return Math.abs(a.x - b.x) <= epsilon && Math.abs(a.y - b.y) <= epsilon;
}

export class Bounds {
    left: number;
    top: number;
    right: number;
    bottom: number;

    constructor(left: number, top: number, right: number, bottom: number) {
        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
    }

    static fromBounds(otherBounds: Bounds): Bounds {
        return new Bounds(otherBounds.left, otherBounds.top, otherBounds.right, otherBounds.bottom);
    }

    static fromDOMRect(rect: DOMRect): Bounds {
        return new Bounds(rect.left, rect.top, rect.right, rect.bottom);
    }

    expand(expandation: number): void {
        this.left -= expandation;
        this.top -= expandation;
        this.right += expandation;
        this.bottom += expandation;
    }

    checkPointIsInside(point: Point): boolean {
        return point.x <= this.right && point.x >= this.left && point.y <= this.bottom && point.y >= this.top;
    }

    checkBoundsIsInside(otherBounds: Bounds): boolean {
        return otherBounds.left <= this.right && otherBounds.right >= this.left && otherBounds.top <= this.bottom && otherBounds.bottom >= this.top;
    }

    equals(otherBounds: Bounds): boolean {
        return otherBounds.left === this.left && otherBounds.top === this.top && otherBounds.right === this.right && otherBounds.bottom === this.bottom;
    }
}

export class Rectangle extends Bounds {

    static override fromBounds(otherBounds: Bounds): Rectangle {
        return new Rectangle(otherBounds.left, otherBounds.top, otherBounds.right, otherBounds.bottom);
    }

    public get width() {
        return this.right - this.left;
    }

    public get height() {
        return this.bottom - this.top;
    }
}

export type TrianglePoints = [Point, Point, Point];

export class Triangle {
    private pa: Point;
    private pb: Point;
    private pc: Point;

    constructor(...points: TrianglePoints) {
        this.pa = points[0];
        this.pb = points[1];
        this.pc = points[2];
    }

    getPoints(): TrianglePoints {
        return [this.pa, this.pb, this.pc];
    }

    checkPointIsInside(point: Point): boolean {
        // https://blackpawn.com/texts/pointinpoly/default.html
        // https://stackoverflow.com/a/9747983

        let dotProduct = (u: Vector2D, v: Vector2D): number => {
            return u.x1 * v.x1 + u.x2 * v.x2;
        }

        let v0 = vector2DBetweenPoints(this.pa, this.pc);
        let v1 = vector2DBetweenPoints(this.pa, this.pb);
        let v2 = vector2DBetweenPoints(this.pa, point);

        let dot00 = dotProduct(v0, v0);
        let dot01 = dotProduct(v0, v1);
        let dot02 = dotProduct(v0, v2);
        let dot11 = dotProduct(v1, v1);
        let dot12 = dotProduct(v1, v2);

        let invDenom = 1 / (dot00 * dot11 - dot01 * dot01)
        let u = (dot11 * dot02 - dot01 * dot12) * invDenom;
        let v = (dot00 * dot12 - dot01 * dot02) * invDenom;

        return (u >= 0) && (v >= 0) && (u + v < 1);
    }
}

/**
 * @description Berechnet den Winkel am Punkt pa im von den drei Punkten aufgespannten Dreieck
 * @param {Point} pa 
 * @param {Point} pb 
 * @param {Point} pc 
 * @returns {number}
 */
export function getAngleByPoints(pa: Point, pb: Point, pc: Point): number {
    // sides
    let a = distancePointPoint(pb, pc);
    let b = distancePointPoint(pc, pa);
    let c = distancePointPoint(pa, pb);

    return radToDeg(Math.acos((-0.5 * Math.pow(a, 2) + 0.5 * Math.pow(b, 2) + 0.5 * Math.pow(c, 2)) / (b * c)));
}

/**
 * @param {number} degValue 
 * @return {number} 
 */
export function degToRad(degValue: number): number {
    return degValue * Math.PI / 180;
}

/**
 * @param {number} radValue 
 * @return {number} 
 */
export function radToDeg(radValue: number): number {
    return radValue * 180 / Math.PI;
}

/**
 * @description Canvas nutzt Winkel "im Uhrzeigersinn" und als Rad statt Deg
 * @param {number} input 
 * @param {boolean} convertToRad 
 * @return {number} 
 */
export function convertMathematicalAngleToJSAngle(input: number, convertToRad: boolean = true): number {
    let result = 360 - input;

    if (convertToRad)
        result = degToRad(result);

    return result;
}


/**
* @param {number} phi 
* @param {number} x
* @param {number} y
* @param {Point} center
* @return {Point} 
*/
export function turnPoint(phi: number, x: number, y: number, center: Point): Point {
    x = x - center.x;
    y = y - center.y;

    return new Point(x * Math.cos(degToRad(phi)) + y * (-Math.sin(degToRad(phi))) + center.x, x * Math.sin(degToRad(phi)) + y * Math.cos(degToRad(phi)) + center.y)
}