/*------------------------------------------*/
/* Console Handling */
/*------------------------------------------*/
export const mtWarning = 0;
export const mtError = 1;
export const mtInformation = 2;
export const mtConfirmation = 3;
export const mtCustom = 4;
export const mtAlert = 5;
export const mtSuccess = 6;
export const mtTodo = 400;
export const mtConsense = 500;

export enum ConsoleMessageType {
    Warning = 0,
    Error = 1,
    Information = 2,
    Success = 6,
    Todo = 400,
    ConSense = 500,
}

export function CsConsole(message: string, type: ConsoleMessageType, size: number = 15): void {
    const commands = [
        [ConsoleMessageType.Warning, "\u26A0 Warnung", "background: #ffcc00;"],
        [ConsoleMessageType.Error, "\uD83D\uDEA8 Error", "background: #cc0000;"],
        [ConsoleMessageType.Information, "\u2139 Info", "background: #0095d4;"],
        [ConsoleMessageType.Success, "\u2705 Erfolg", "background: #99cc33;"],
        [ConsoleMessageType.Todo, "\uD83D\uDCAA ToDo", "background: #669900;"],
        [ConsoleMessageType.ConSense, "\uD83D\uDC4C ConSense", "background: #0066cc;"],
    ];
    
    let command = commands.find(c => c[0] === type);

    console.log("%c" + command[1] + ": " + message, "font-size: " + size + "px;display:block;padding:5px;color:white;" + command[2]);
}