/*------------------------------------------*/
/* Communications **/
/*------------------------------------------*/

export let CSEndpointGuid: string = '';

function registerEndpointGuid(guid: string): void {
    CSEndpointGuid = guid;
}

/*------------------------------------------*/
/* URL */
/*------------------------------------------*/

export function getURLParameter(param: string): string {
    let url = new URL(window.location.href);
    return url.searchParams.get(param) ?? '';
}

export function getBaseUrl(id: string): string {
    return getBaseUrlObj(id).href;
}

export function getBaseUrlObj(id: string): URL {
    let url = new URL(window.location.href);
    url.searchParams.append('component', id);
    url.searchParams.append('endpointguid', CSEndpointGuid);

    return url;
}


// für Delphi veröffentlichen
window[registerEndpointGuid.name] = registerEndpointGuid;
