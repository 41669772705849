import { SimpleEventDispatcher } from "ste-simple-events";
import { ISimpleEvent } from "strongly-typed-events";
import { Point } from "../classes/geometry";

export let cntrlIsPressed = false;
export let altIsPressed = false;
export let shiftIsPressed = false;
export let mouseDownLeft = false;

let __mouseX: number;
let __mouseY: number;

let __keyboardDispatcher = new SimpleEventDispatcher<KeyboardEvent>();

// event.key Parameter können via https://keycode.info/ ermittelt werden!

export function registerConSenseKeyboardEvents() {
    document.onkeydown = handleGlobalKeyDownEvent;
    document.onkeypress = handleGlobalSharedKeyEvent;
    document.onkeyup = handleGlobalKeyUpEvent;
}

export function registerConSenseMouseEvents() {
    document.addEventListener('mousedown', () => toggleMouseState(true));
    document.addEventListener('mouseup', () => toggleMouseState(false));
    document.addEventListener('mousemove', event => handleMouseMoveEvent(event));
}

function toggleMouseState(isDown) {
    mouseDownLeft = isDown;
}

function handleMouseMoveEvent(event: MouseEvent) {
    __mouseX = event.x;
    __mouseY = event.y;
}

export function getCurrentGlobalMousePosition(): Point {
    return new Point(__mouseX, __mouseY);
}

function handleGlobalKeyDownEvent(event: KeyboardEvent): void {
    switch (event.key) {
        case 'Shift':
            shiftIsPressed = true;
            break;
        case 'Control':
            cntrlIsPressed = true;
            break;
        case 'Alt':
            altIsPressed = true;
            break;
    }

    handleGlobalSharedKeyEvent(event);
}

function handleGlobalKeyUpEvent(event: KeyboardEvent): void {
    switch (event.key) {
        case 'Shift':
            shiftIsPressed = false;
            break;
        case 'Control':
            cntrlIsPressed = false;
            break;
        case 'Alt':
            altIsPressed = false;
            break;
    }

    handleGlobalSharedKeyEvent(event);
}

function handleGlobalSharedKeyEvent(event: KeyboardEvent): void {
    __keyboardDispatcher.dispatch(event);
}

export function onGlobalKeyEvent(): ISimpleEvent<KeyboardEvent> {
    return __keyboardDispatcher.asEvent();
}