import { assigned, isNumeric } from "./helper";

export function AsType<T extends HTMLElement>(element: HTMLElement, throwError: boolean = true): T {
    if (throwError && !assigned(element)) {
        throw `Element is not assigned.`;
    }

    let result = element as T;

    if (throwError && !assigned(result)) {
        throw `Element is not of the correct type [${element.id}].`
    }

    return result;
}

/*------------------------------------------*/
/* unique Id**/
/*------------------------------------------*/
export function getUniqueId(prefix?: string, suffix?: string): string {
    if (!assigned(prefix) || isNumeric(prefix.charAt(0))) {
        // id mit einer zahl vorne ist nicht erlaubt in HTML bsp: id="123" 
        let charset = 'abcdefghijklmnopqrstuvwxyz';
        prefix = charset[Math.floor(Math.random() * charset.length)];
    }

    let result = prefix + Math.random().toString(36).slice(2) + performance.now();

    if (assigned(suffix)) {
        result = result + suffix;
    }

    return result.replace('.', '');
}


/*------------------------------------------*/
/* get all parents of node **/
/*------------------------------------------*/

export function getParents(element: Element): Array<Element> {
    let parents = [];
    let p = element.parentNode;
    while (p !== document) {
        let o = p;
        parents.push(o);
        p = o.parentNode;
    }
    parents.push(document);
    return parents;
}


/*------------------------------------------*/
/* get parent with Classname **/
/*------------------------------------------*/

export function getParentByClassname(element: Element, targetClass: string): Element | null {
    let parents = getParents(element);

    for (let i = 0; i < parents.length; i++) {
        // gibt es nun die gesuchte klasse in meiner liste? Dann raus
        if (parents[i].classList?.contains(targetClass)) {
            return parents[i];
        }
    }
    return null;
}

export function getParentBySelectors(element: Element, selectors: string): Element | null {
    let parents = getParents(element);

    for (let i = 0; i < parents.length; i++) {
        // matches funktioniert nicht auf <!DOCTYPE html>
        if (parents[i].nodeType !== Node.DOCUMENT_NODE) {
            if (parents[i].matches(selectors)) {
                return parents[i];
            }
        }
    }

    return null;
}