export function onReady(fn: () => any): void {
    if (document.readyState != 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}
// Die müssen wir nach außen freigeben
window[onReady.name] = onReady;

export function assigned(obj: unknown): boolean {
    return obj !== null && typeof obj !== 'undefined';
}
window[assigned.name] = assigned; // Alter Prozesseditor :(

export function isNumeric(value: string): boolean {
    return !isNaN(parseInt(value))
}

export function X(s: string): string {
    if (!assigned(s))
        return '';

    let buf = [];
    for (let i = s.length - 1; i >= 0; i--) {
        buf.unshift(['&#', s.charCodeAt(i), ';'].join(''));
    }
    return buf.join('');
}

export function UnX(s: string): string {
    if (!assigned(s))
        return '';

    return s.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec);
    });
}