import { Notyf, NotyfNotification } from "notyf";
import { isDev } from "../../utils/devUtils";
import { assigned } from "../../utils/helper";

export enum MessageType {
    Primary = 'primary',
    Secondary = 'secondary',
    Warning = 'warning',
    Success = 'success',
    Danger = 'danger',
    Info = 'info',
    Light = 'light',
    Dark = 'dark',
}

export type NotifyDuration = number | false;


// Instanz erst on Demand erzeugen; spart Resourcen beim Laden und wir sparen uns zu prüfen ob der body schon da ist
let __noty: Notyf;

function createNewNotiyfInstance(): Notyf {
    return new Notyf({
        position: {
            x: 'right',
            y: 'top',
        },
        ripple: false,
        types: [
            {
                type: MessageType.Primary,
                className: `bg-${MessageType.Primary}`,
                icon: {
                    className: 'fa-solid fa-info-circle',
                    tagName: 'i',
                    text: '',
                },
                dismissible: true,
            },
            {
                type: MessageType.Secondary,
                className: `bg-${MessageType.Secondary} text-dark`,
                icon: {
                    className: 'fa-solid fa-info-circle',
                    tagName: 'i',
                    text: '',
                },
                dismissible: true,
            },
            {
                type: MessageType.Warning,
                className: `bg-${MessageType.Warning} text-dark`,
                icon: {
                    className: 'fa-solid fa-exclamation-circle',
                    tagName: 'i',
                    text: '',
                },
                dismissible: true,
            },
            {
                type: MessageType.Success,
                className: `bg-${MessageType.Success} text-dark`,
                icon: {
                    className: 'fa-solid fa-check-circle text-dark',
                    tagName: 'i',
                    text: '',
                },
                dismissible: true,
            },
            {
                type: MessageType.Danger,
                className: `bg-${MessageType.Danger}`,
                icon: {
                    className: 'fa-solid fa-exclamation-triangle',
                    tagName: 'i',
                    text: '',
                },
                dismissible: true,
            },
            {
                type: MessageType.Info,
                className: `bg-${MessageType.Info}`,
                icon: {
                    className: 'fa-solid fa-info-circle',
                    tagName: 'i',
                    text: '',
                },
                dismissible: true,
            },
            {
                type: MessageType.Light,
                className: `bg-${MessageType.Light} text-dark`,
                icon: {
                    className: 'fa-solid fa-info-circle',
                    tagName: 'i',
                    text: '',
                },
                dismissible: true,
            },
            {
                type: MessageType.Dark,
                className: `bg-${MessageType.Dark}`,
                icon: {
                    className: 'fa-solid fa-info-circle',
                    tagName: 'i',
                    text: '',
                },
                dismissible: true,
            },
        ]
    });
}

function NotyfInstance(): Notyf {
    if (!assigned(__noty)) {
        __noty = createNewNotiyfInstance();
    }

    return __noty;
}

export function MsgNotifyDEV(message: string): NotyfNotification {
    if (isDev()) {
        return MsgNotifyEx(message, MessageType.Dark, 0);
    }
}

export function MsgNotify(message: string, type: MessageType): NotyfNotification {
    return MsgNotifyEx(message, type, 5000); // Default 5 Sekunden
}

export function MsgNotifyEx(message: string, messageType: MessageType, milliSeconds: NotifyDuration, theme: string = 'consense') {
    if (typeof milliSeconds === 'boolean') {
        return MsgNotifyEx(message, messageType, 0, theme);
    } else {
        let notification = NotyfInstance().open({
            type: messageType,
            message: message,
            duration: milliSeconds,
        });

        return notification;
    }
}

export function ClearNotification(notification: any): void {
    NotyfInstance().dismiss(notification);
}

export function ClearAllNotifications(): void {
    NotyfInstance().dismissAll();
}